import { Cookies } from 'react-cookie'
require('dotenv').config()

export const env = process.env.REACT_APP_ENVIRONMENT
export const apiURL = process.env.REACT_APP_API_URL

const cookies = new Cookies()
let token = ''
if (env === "dev") {
    token = process.env.REACT_APP_DEMO_USER_TOKEN
} else {
    token = cookies.get('production_mf_app_jwt_token')
    //console.log('EO GA KUKI', token)
}

/**
 * Ova metodate ce da spremi timestamps za trenutni mesec, prosli mesec i naredni mesec.
 * Ovi timestamps se prosledjuju kao filteri u api call za listu invojsa
 * 
 * Input parametar je timestamp
 * 
 * Da se ne bih jebavao sa prestupnim godinama i brojevima dana u mesecu, hardkodovacu timestamps
 */
export const getTimeRanges = (currentPeriod) => {

    let timestamps = {
        '122022': {
            start: 1669849200,
            end: 1672527600,
            label: 'Decembar 2022'
        },
        '012023': {
            start: 1672527600, 
            end: 1675206000,
            label: 'Januar 2023'
        },
        '022023': {
            start: 1675206000,
            end: 1677625200,
            label: 'februar 2023'
        },
        '032023': {
            start: 1677625200,
            end: 1680300000,
            label: 'Mart 2023'
        },
        '042023': {
            start: 1680300000,
            end: 1682892000,
            label: 'April 2023'
        },
        '052023': {
            start: 1682892000,
            end: 1685570400,
            label: 'Maj 2023'
        },
        '062023': {
            start: 1685570400,
            end: 1688162400,
            label: 'Jun 2023'
        },
        '072023': {
            start: 1688162400,
            end: 1690840800,
            label: 'Jul 2023'
        },
        '082023': {
            start: 1690840800,
            end: 1693519200,
            label: 'Avgust 2023'
        },
        '092023': {
            start: 1693519200,
            end: 1696111200,
            label: 'Septembar 2023'
        },
        '102023': {
            start: 1696111200,
            end: 1698793200,
            label: 'Oktobar 2023'
        },
        '112023': {
            start: 1698793200,
            end: 1701385200,
            label: 'Novembar 2023'
        },
        '122023': {
            start: 1701385200,
            end: 1704063600,
            label: 'Decembar 2023'
        },
        '012024': {
            start: 1704063600,
            end: 1706741999,
            label: 'Januar 2024'
        }
        ,
        '022024': {
            start: 1706749200,
            end: 1709254799,
            label: 'Februar 2024'
        }
        ,
        '032024': {
            start: 1709254800,
            end: 1711933199,
            label: 'Mart 2024'
        }
        ,
        '042024': {
            start: 1711933200,
            end: 1714514400,
            label: 'April 2024'
        }
        ,
        '052024': {
            start: 1714514401,
            end: 1717192800,
            label: 'Maj 2024'
        }
        ,
        '062024': {
            start: 1717192801,
            end: 1719791999,
            label: 'Jun 2024'
        },
        '072024': {
            start: 1719792000,
            end: 1722470399,
            label: 'Jul 2024'
        },
        '082024': {
            start: 1722470400,
            end: 1725148799,
            label: 'Avgust 2024'
        },
        '092024': {
            start: 1725148800,
            end: 1727740799,
            label: 'Septembar 2024'
        },
        '102024': {
            start: 1727740800,
            end: 1730419199,
            label: 'Oktobar 2024'
        },
        '112024': {
            start: 1730419200,
            end: 1733011199,
            label: 'Novembar 2024'
        },
        '122024': {
            start: 1733011200,
            end: 1735689599,
            label: 'Decembar 2024'
        },
        '012025': {
            start: 1735686000,
            end: 1738364399,
            label: 'Januar 2025'
        },
        '022025': {
            start: 1738364400,
            end: 1740783600,
            label: 'Februar 2025'
        },
        '032025': {
            start: 1740783600,
            end: 1743461999,
            label: 'Februar 2025'
        },
        

    }

    return timestamps[currentPeriod]
}

export const getCurrentTimeRangeKey = () => {
    let currentRange = new Date()
    let dateRange = ('0'+(currentRange.getMonth()+1)).slice(-2)+currentRange.getFullYear()
    return dateRange
}
export const getUserInvoices = async (dateRange) => {
    try {
        let endpointUrl = apiURL + '/emf/get-user-invoices'

        //Mozda ovde neki if da pitam da li je daterange prosledjen uopste
        if(dateRange === undefined) {
            dateRange = getCurrentTimeRangeKey()
        }

        let timeFrame = getTimeRanges(dateRange)
        // console.log('DATE RANGE', dateRange)
        // console.log(timeFrame)
        
        if(timeFrame !== undefined){
            endpointUrl = endpointUrl + '?date_from='+timeFrame.start+'&date_to='+timeFrame.end
        }

        console.log('Selected date range', dateRange, endpointUrl,timeFrame )
        const invoices = await fetch(
            endpointUrl,
            {
                method: 'GET',
                headers: {'authorization': token}
            }
        )

        if(invoices.status === 200) {
            let userInvoices = await invoices.json()
            return userInvoices
        } else {
            return false
        }
    } catch (error) {
        console.log('Error while fetching user invoices', error)
        return false
    }
}

export const getSingleUserInvoice = async (invoiceId) => {
    try {
        const invoices = await fetch(
            apiURL + '/emf/get-user-invoices?invoice_id='+invoiceId,
            {
                method: 'GET',
                params: {invoice_id: invoiceId},
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                }
            }
        )

        if(invoices.status === 200) {
            let userInvoices = await invoices.json()
            return userInvoices
        } else {
            return false
        }
    } catch (error) {
        console.log('Error while fetching user invoices', error)
        return false
    }
}

export const setNewBuyerEmail = async (invoiceId, newBuyerEmail) => {
    try {
        const updateBuyerEmail = await fetch(
            apiURL+'/emf/set-buyer-email',
            {
                method: 'POST',
                body: JSON.stringify({
                    invoice_id: invoiceId,
                    email: newBuyerEmail
                }),
                headers: {
                    'authorization': token,
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                }
            }
        )
        if(updateBuyerEmail.status === 200) {
            let responseData = await updateBuyerEmail.json()
            return responseData
        } else {
            console.log('Greske prilikom apdejta buyer email adrese', updateBuyerEmail.status)
            return false
        }
    } catch (error) {
        console.log('Error while trying to update buyer email', error)
        return false
    }
}

export const setNewBuyerJMBG = async (invoiceId, buyerJMBG) => {
    try {
        const updateBuyerJMBG = await fetch(
            apiURL+'/emf/set-buyer-jmbg',
            {
                method: 'POST',
                body: JSON.stringify({
                    invoice_id: invoiceId,
                    jmbg: buyerJMBG
                }),
                headers: {
                    'authorization': token,
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                }
            }
        )
        if(updateBuyerJMBG.status === 200) {
            let responseData = await updateBuyerJMBG.json()
            return responseData
        } else {
            console.log('Greske prilikom apdejta buyer jmbg-a', updateBuyerJMBG.status)
            return false
        }
    } catch (error) {
        console.log('Error while trying to update buyer jmbg', error)
        return false
    }
}
export const updateOrderArticleJm = async(invoiceId, orderArticleId, jmId) => {
    try {
        const updateOAJM = await fetch(
            apiURL+'/emf/set-order-article-jm',
            {
                method: 'POST',
                body: JSON.stringify({
                    invoice_id: invoiceId,
                    order_article_id: orderArticleId,
                    jm_id: jmId
                }),
                headers: {
                    'authorization': token,
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                }
            }
        )
        if(updateOAJM.status === 200) {
            let responseData = await updateOAJM.json()
            return responseData
        } else {
            console.log('Greske prilikom apdejta order article JM', updateOAJM.status)
            return false
        }
    } catch (error) {
        console.log('Error while trying to update order article JM', error)
        return false
    }
}

export const updateOrderArticleTaxExemption = async (invoiceId, orderArticleId, taxExemptionId, brojResenja) => {
    try {
        const updateOAtaxExemption = await fetch(
            apiURL+'/emf/set-order-article-tax-exemption',
            {
                method: 'POST',
                body: JSON.stringify({
                    invoice_id: invoiceId,
                    order_article_id: orderArticleId,
                    tax_exemption_id: taxExemptionId,
                    broj_resenja: brojResenja
                }),
                headers: {
                    'authorization': token,
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                }
            }
        )
        if(updateOAtaxExemption.status === 200) {
            let responseData = await updateOAtaxExemption.json()
            return responseData
        } else {
            console.log('Greske prilikom apdejta order article tax exemption', updateOAtaxExemption.status)
            return false
        }
    } catch (error) {
        console.log('Error while trying to update order article tax exemption', error)
        return false
    }   
}

export const setDocumentContractNumber = async (invoiceId, brojUgovora) => {
    try {
        const newDocumentContract = await fetch(
            apiURL+'/emf/set-document-contract',
            {
                method: 'POST',
                body: JSON.stringify({
                    invoice_id: invoiceId,
                    broj_ugovora: brojUgovora
                }),
                headers: {
                    'authorization': token,
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                }

            }
        )
        if(newDocumentContract.status === 200) {
            let responseData = await newDocumentContract.json()
            return responseData
        } else {
            console.log('Greske prilikom apdejta ugovora za dokument', newDocumentContract.status)
            return false
        }
    } catch (error) {
        console.log('Error while setting document contract', error)
        return false
    }
}

export const sendInvoiceToSef = async (invoiceId, sendToCir) => {
    try {
        const sefResponse = await fetch(
            apiURL+'/emf/send-document-to-sef',
            {
                method: 'POST',
                body: JSON.stringify({
                    invoice_id: invoiceId,
                    send_to_cir: sendToCir
                }),
                headers: {
                    'authorization': token,
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                }

            }
        )
        if(sefResponse.status === 200) {
            let responseData = await sefResponse.json()
            return responseData
        } else {
            console.log('Greske prilikom slanja dokumenta u SEF', sefResponse.status)
            return false
        }
    } catch (error) {
        console.log('Error while sending document to SEF', error)
        return false
    }
}

export const setBuyerJbkjs = async (invoiceId, jbkjsBroj) => {
    try {
        const newBuyerJBKJS = await fetch(
            apiURL+'/emf/set-buyer-jbkjs',
            {
                method: 'POST',
                body: JSON.stringify({
                    invoice_id: invoiceId,
                    jbkjs_broj: jbkjsBroj
                }),
                headers: {
                    'authorization': token,
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                }

            }
        )
        if(newBuyerJBKJS.status === 200) {
            let responseData = await newBuyerJBKJS.json()
            return responseData
        } else {
            console.log('Greske prilikom apdejta buyer JBKJS broja', newBuyerJBKJS.status)
            return false
        }
    } catch (error) {
        console.log('Error while setting buyer JBKJS', error)
        return false
    }
}

export const getPoreskaOslobodjenja = async() => {
    try {
        const taxExemptions = await fetch (
            apiURL+'/emf/get-poreska-oslobodjenja',
            {
                method: 'GET',
                headers: {
                    'authorization': token,
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                }
            }
        )

        if (taxExemptions.status === 200) {
            let responseData = await taxExemptions.json()
            return responseData
        } else {
            console.log('Error while fetching tax exemption codes', taxExemptions.status)
            return false
        }
    } catch (error) {
        console.log('Error while fetching available tax exemption codes', error)
        return false
    }
}
/**
 * Ova metoda ce proveriti preko emf API-a da li korisnik ima setapovan SEF Api.
 * Ako ima, vratice true, ako nema vratice false.
 */
export const getUserSefApiStatus = async () => {
    console.log('API URL JE XXX', apiURL)
    try {
        const userApiStatus = await fetch(apiURL+'/emf/user-api-status', {method: 'GET', headers: {'authorization': token}})
        console.log('Usr API status', userApiStatus.status)

        if (userApiStatus.status === 200) {
            const data = await userApiStatus.json()
            return data.sef_api_key
        }

        if (userApiStatus.status === 401) {
            return null
        } else {
            console.log('Nesto nije ok sa endpointom')
            return false
        }
    } catch (error) {
        return false
    }
}

export const postUpdateUserSefKey = async (sefApiKey) => {
    try {
        console.log('Posting to', apiURL+'/emf/set-user-sef-key')
        const updateApiKey = await fetch(
            apiURL+'/emf/set-user-sef-key',
            {
                method: 'POST',
                headers: {
                    'authorization': token,
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({api_key: sefApiKey})
            }
        )
        if(updateApiKey.status === 200) {
            return true
        } else {
            console.log('Greske prilikom apdejta sef api keya', updateApiKey.status)
            return false
        }

    } catch (error) {
        console.log('Error while setting the SEF API Key')
        return false
    }
}

export const checkApiStatus = async () => {
    try {
        const apiStatus = await fetch(
            apiURL+'/status',
            {
                method: 'get',
            }
        )
        if(apiStatus.status === 200) {
            return true
        } else {
            console.log('Api trenutno ne reaguje', apiStatus.status)
            return false
        }
    } catch (error) {
        console.log('error while checking the API status', error)
        return null
    }
}